<template>
  <div class="flexi-pop-up-question-multi__wrapper">
    <div class="flexi-quiz__content">
      <div class="q">
        <div class="flexi-quiz__content--question">
          <span
            class="question"
            v-text="question.question"
            :data-aos="contentAos ? contentAosType : ''"
            :data-aos-duration="contentAosDuration"
          />
          <span
            class="description"
            v-if="question.suggestion"
            v-html="question.suggestion"
          />
        </div>
        <div
          class="flexi-quiz__content--answer"
          v-for="(value, key) in question.answers"
          :key="key"
          :data-aos="contentAos ? contentAosType : ''"
          :data-aos-duration="contentAosDuration"
        >
          <div
            class="answer"
            @click="selectAnswer(value)"
            :class="{
              'selected-answer': value.id && checkSelectedAnswer(value.id),
              'correct-answer':
                scoreIsCalculated && checkIfAnswerIsCorrect(value)
            }"
          >
            <div class="circle" />
            <span class="text" v-text="value.answer" />
          </div>
        </div>

        <transition mode="in-out" name="fade">
          <div v-if="scoreIsCalculated" class="score-description">
            <span
              v-text="selectedScore ? this.correctScore : this.wrongScore"
              :class="selectedScore ? 'correct-score' : 'wrong-score'"
            />
          </div>
        </transition>

        <div
          v-if="!scoreIsCalculated"
          class="button__wrapper"
          :class="{ active: active }"
        >
          <button
            @click="calculateScore"
            v-text="'Proverite rezultat'"
            class="flexi-btn"
          />
        </div>

        <div v-else class="button__wrapper" :class="{ active: active }">
          <button @click="closeModal" v-text="'Zatvori'" class="flexi-btn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentAos: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    contentAosType: {
      type: String,
      default: () => {
        return "fade-right";
      }
    },
    contentAosDuration: {
      type: String,
      default: () => {
        return "1500";
      }
    },
    question: {
      type: Object
    }
  },
  data() {
    return {
      active: false,
      correctScore: "Odličan, dobar posao.",
      wrongScore: "Ne odustajte, potražite pravi odgovor u ovom odeljku.",
      selectedScore: null,
      scoreIsCalculated: false,
      selectedAnswer: false,
      selectedAnswerCorrect: null,
      selectedAnswerId: null,
      selectedAnswerArray: []
    };
  },
  methods: {
    calculateScore() {
      // calculation
      var answers = JSON.parse(JSON.stringify(this.question.answers));

      answers = answers.filter(el => {
        return el.correct;
      });

      var ids = new Set(answers.map(d => d.id));
      var merged = [
        ...answers,
        ...this.selectedAnswerArray.filter(d => !ids.has(d.id))
      ];

      if (merged.length > this.selectedAnswerArray.length) {
        this.selectedScore = false;
      } else {
        if (merged.some(el => !el.correct)) {
          this.selectedScore = false;
        } else {
          this.selectedScore = true;
        }
      }

      //set that the score is calculated to show message and to change the button
      this.scoreIsCalculated = true;

      this.$emit("scoreIsCalculated", this.selectedScore);
    },
    checkIfAnswerIsCorrect(answer) {
      if (this.selectedScore) {
        return this.selectedAnswerArray.some(
          el =>
            el.id === answer.id && el.correct && el.correct === answer.correct
        );
      }
    },
    checkSelectedAnswer(id) {
      return this.selectedAnswerArray.some(el => el.id === id);
    },
    closeModal() {
      if (!this.user) {
        this.exitModal();
      } else {
        this.$emit("closeModal", this.selectedAnswerArray);
        this.restartQuestion();
        this.$emit("removeModalClass");
      }
    },
    selectAnswer(answer) {
      if (!this.scoreIsCalculated) {
        //if score is not calculated yet
        if (this.selectedAnswerArray.length > 0) {
          if (this.selectedAnswerArray.some(el => el.id === answer.id)) {
            //if exist in array remove it
            this.selectedAnswerArray = this.selectedAnswerArray.filter(function(
              ans
            ) {
              return ans.id !== answer.id;
            });
          } else {
            //if not exist in array push it
            this.selectedAnswerArray.push(answer);
          }
        } else {
          //if array is empty push it
          this.selectedAnswerArray.push(answer);
        }
      }
    },
    exitModal() {
      document.getElementById("pop-up-question").remove();
    },
    restartQuestion() {
      this.active = false;
      this.selectedScore = null;
      this.scoreIsCalculated = false;
      this.selectedAnswer = false;
      this.selectedAnswerCorrect = null;
      this.selectedAnswerId = null;
      this.selectedAnswerArray = [];
    }
  }
};
</script>
