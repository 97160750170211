export const subHeaderSecondUnit = [
  {
    name: "Medijsko vlasništvo i selekcija vesti",
    href: "/unit-2/part-a",
    isPage: false
  },
  {
    name: "Sloboda Izražavanja i Medijske Slobode",
    href: "/unit-2/part-b",
    isPage: false
  }
];

export const subHeaderThirdUnit = [
  {
    name: "Kako funkcionišu algoritmi",
    href: "/unit-3/part-a",
    isPage: false
  },
  {
    name: "Kognitivne distorzije",
    href: "/unit-3/part-b",
    isPage: false
  }
];

export const subHeaderFourthUnit = [
  {
    name: "Šta su dezinformacije?",
    href: "/unit-4/part-a",
    isPage: false
  },
  {
    name: "Kako da se zaštitimo od dezinformacija?",
    href: "/unit-4/part-b",
    isPage: false
  }
];
