<template>
  <div class="flexi-container page__internal page__unit-6">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[14].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <span v-text="'Poglavlje 6'" class="sub-header__unit" />
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-5')"
              class="sub-header__pagination--item"
              v-text="'Prethodno'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Nastavi'"
              @click="goToRoute('/final-test')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
      <template v-slot:content="slotProps">
        <h1 class="title" v-text="slotProps.inputData.title" />
        <div class="subtitle" v-text="slotProps.inputData.subTitle" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].sixthUnit"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- Transcript Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[14].sections[3]"
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--title"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' +
                slotProps.inputData.contentTitle
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
          <img
            src="https://app.imgforce.com/images/user/KxO_1634752319_video%201.png"
          />
        </div>
        <p
          class="transcript-link"
          @click="expandTranscript"
          v-text="inputData.internalPages[14].sections[3].contentLine"
        />
        <transition
          name="slide"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <p
            v-show="expandVideoTranscript"
            class="transcript-text"
            id="transcript"
            v-html="inputData.internalPages[14].sections[3].description"
          />
        </transition>
      </template>
    </flexi-wrapper>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[14].sections[0]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--title"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :turnTimer="false"
          :transitionMode="'out-in'"
          :transitionName="'fade'"
          :prevButtonText="''"
          :nextButtonText="''"
        />
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[14].sections[2]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlexiSlider from "../../components/FlexiSlider.vue";
import FlexiScrollingSlider from "../../components/FlexiScrollingSlider.vue";

export default {
  name: "unit-5",
  metaInfo() {
    return {
      title: "PROMIŠLJENO PROVERENO - Poglavlje 6. Šta smo naučili?",
      meta: [
        {
          name: "description",
          content: "online kurs medijske pismenosti"
        },
        {
          name: "og:title",
          content: "PROMIŠLJENO PROVERENO - Poglavlje 6. Šta smo naučili?"
        },
        {
          name: "og:description",
          content: "online kurs medijske pismenosti"
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/bt6_1646727151_unit6_seo.png"
        },
        { name: "twitter:card", content: "online kurs medijske pismenosti" },
        {
          name: "twitter:title",
          content: "PROMIŠLJENO PROVERENO - Poglavlje 6. Šta smo naučili?"
        },
        {
          name: "twitter:description",
          content: "online kurs medijske pismenosti"
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/bt6_1646727151_unit6_seo.png"
        }
      ]
    };
  },
  components: {
    FlexiSlider,
    FlexiScrollingSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      expandVideoTranscript: false,
      openContent: false
    };
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].fifthUnit[0].string_id;
    document.getElementById("top-card").scrollIntoView();
  },
  beforeDestroy() {
    if (!this.score.scorePogress.unit6) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit6");
      this.setLastUnit("/unit-6");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
