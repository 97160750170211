<template>
  <div class="flexi-container page__internal page__unit-3-part-b">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderThirdUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-3/part-a')"
              class="sub-header__pagination--item"
              v-text="'Prethodno'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Nastavi'"
              @click="goToRoute('/unit-4')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].thirdUnitPartB"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[0]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/uh2_1634752320_video%207.png"
          />
        </div>
        <p
          class="transcript-link"
          @click="expandTranscript"
          v-text="inputData.internalPages[6].sections[5].contentLine"
        />
        <transition
          name="slide"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <p
            v-show="expandVideoTranscript"
            class="transcript-text"
            id="transcript"
            v-html="inputData.internalPages[6].sections[5].description"
          />
        </transition>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[1]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          class="flexi-wrapper__section--content--description"
          v-html="slotProps.inputData.description"
        />
      </template>
    </flexi-wrapper>

    <!-- Third Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[4]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          class="flexi-wrapper__section--content--description"
          v-html="slotProps.inputData.description"
        />
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[3]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[10]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderThirdUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";

export default {
  name: "part-b",
  metaInfo() {
    return {
      title: "Poglavlje 3: Društvene Mreže",
      meta: [
        {
          name: "description",
          content:
            "Zašto smo tako podložni manipulacijama? Saznaj šta se dešava sa našim kognitivnim aparatom kada dođe u kontakt sa informacijama i kako mediji i društvene mreže mogu to da upotrebe u svoju korist."
        },
        {
          name: "og:title",
          content: "Poglavlje 3: Društvene mreže"
        },
        {
          name: "og:description",
          content:
            "Zašto smo tako podložni manipulacijama? Saznaj šta se dešava sa našim kognitivnim aparatom kada dođe u kontakt sa informacijama i kako mediji i društvene mreže mogu to da upotrebe u svoju korist."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/QYD_1638501998_seo_ks_u3b.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Poglavlje 3: Društvene mreže"
        },
        {
          name: "twitter:description",
          content:
            "Zašto smo tako podložni manipulacijama? Saznaj šta se dešava sa našim kognitivnim aparatom kada dođe u kontakt sa informacijama i kako mediji i društvene mreže mogu to da upotrebe u svoju korist."
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/QYD_1638501998_seo_ks_u3b.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiScrollingSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      answeredPopUpFirstQuestion: false,
      expandVideoTranscript: false,
      openContent: false,
      showPopUpFirstQuestion: false,
      subHeaderKey: 1,
      subHeaderThirdUnit: subHeaderThirdUnit
    };
  },
  methods: {
    closeModal(data) {
      if (!this.score.scorePogress.unit3PartB) {
        this.setSelectedAnswersPopUp(data);
      }

      this.answeredPopUpFirstQuestion = true;
      location.hash = "#cognitive-distortions";
      this.$router.push("/unit-3/part-b");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit3PartB) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].thirdUnitPartB[0].string_id;
    document.getElementById("top-card").scrollIntoView();
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit3PartB
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit3PartB");
      this.setLastUnit("/unit-4/part-a");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
