<template>
  <div class="flexi-container page__internal page__unit-2-part-b">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderSecondUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-2/part-a')"
              class="sub-header__pagination--item"
              v-text="'Prethodno'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Nastavi'"
              @click="goToRoute('/unit-3')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].secondUnitPartB"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[0]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/98q_1634752320_video%205.png"
          />
        </div>
        <p
          class="transcript-link"
          @click="expandTranscript"
          v-text="inputData.internalPages[3].sections[8].contentLine"
        />
        <transition
          name="slide"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <div
            v-show="expandVideoTranscript"
            class="flexi-wrapper__section--content--paragraph"
          >
            <p v-html="inputData.internalPages[3].sections[8].description" />
          </div>
        </transition>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[3]"
      v-sectionview
      v-view="popUpSecondQuestion"
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <div
          class="flexi-wrapper__section--description"
          v-html="slotProps.inputData.description"
        />
      </template>
    </flexi-wrapper>

    <!-- Third Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[2]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--title"
          v-text="slotProps.inputData.title"
        />
        <div
          class="flexi-wrapper__section--title--sub-title"
          v-text="slotProps.inputData.subTitle"
        />
      </template>

      <template v-slot:content="slotProps">
        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :turnTimer="false"
          :transitionMode="'out-in'"
          :transitionName="'fade'"
          :prevButtonText="''"
          :nextButtonText="''"
        >
          <h3
            class="flexi-wrapper__section--content--content--subTitle"
            v-text="slotProps.inputData.subTitle"
          />
          <p
            class="flexi-wrapper__section--content--content-paragraph"
            v-text="slotProps.inputData.paragraph"
          />
        </flexi-slider>
        <p
          v-html="slotProps.inputData.description"
          class="flexi-wrapper__section--content--description"
        />
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[6]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[6]"
            @closeModal="closeModal"
          />
          <flexi-pop-up-question-multi
            v-if="showPopUpSecondQuestion"
            :question="inputData.quizzes[3].questions[7]"
            @closeModal="closeModal"
            @removeModalClass="additionalModalClass = ''"
            @scoreIsCalculated="addModalClass"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderSecondUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiPopUpQuestionMulti from "../../../components/FlexiPopUpQuestionMulti.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";
import FlexiSlider from "../../../components/FlexiSlider.vue";

export default {
  name: "part-b",
  metaInfo() {
    return {
      title: "VERY VERIFIED - Unit 2. Ownership and editorial policies",
      meta: [
        {
          name: "description",
          content:
            "What are editorial policies and editorial firewalls? Learn who determines which stories are covered in the media, and in what format."
        },
        {
          name: "og:title",
          content: "VERY VERIFIED - Unit 2. Ownership and editorial policies"
        },
        {
          name: "og:description",
          content:
            "What are editorial policies and editorial firewalls? Learn who determines which stories are covered in the media, and in what format."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/VZz_1646727151_unit2_seo.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "VERY VERIFIED - Unit 2. Ownership and editorial policies"
        },
        {
          name: "twitter:description",
          content:
            "What are editorial policies and editorial firewalls? Learn who determines which stories are covered in the media, and in what format."
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/VZz_1646727151_unit2_seo.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiPopUpQuestionMulti,
    FlexiScrollingSlider,
    FlexiSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      additionalModalClass: "",
      answeredPopUpFirstQuestion: false,
      answeredPopUpSecondQuestion: false,
      expandVideoTranscript: false,
      openContent: false,
      showPopUpFirstQuestion: false,
      showPopUpSecondQuestion: false,
      showQuizz: false,
      subHeaderKey: 1,
      subHeaderSecondUnit: subHeaderSecondUnit,
      quizPrecent: [
        {
          x: "96%",
          y: "4%"
        },
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "98%",
          y: "2%"
        },
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "90%",
          y: "10%"
        },
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "93%",
          y: "7%"
        },
        {
          x: "88%",
          y: "12%"
        },
        {
          x: "92%",
          y: "8%"
        },
        {
          x: "80%",
          y: "20%"
        }
      ]
    };
  },
  methods: {
    closeModal(data) {
      if (this.showPopUpFirstQuestion === true) {
        this.answeredPopUpFirstQuestion = true;
        location.hash = "#how-does-a-story";
        this.$router.push("/unit-2/part-b");
      } else {
        this.answeredPopUpSecondQuestion = true;
        location.hash = "#freedom-of-press";
        this.$router.push("/unit-2/part-b");
      }

      if (!this.score.scorePogress.unit2PartB) {
        this.setSelectedAnswersPopUp(data);
      }
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit2PartB) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpSecondQuestion = false;
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    popUpSecondQuestion(e) {
      if (!this.score.scorePogress.unit2PartB) {
        if (!this.answeredPopUpSecondQuestion) {
          if (e.percentTop > 0.608 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = false;
            this.showPopUpSecondQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    startQuizz(id) {
      this.smoothScroll(id);
      this.showQuizz = true;
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].secondUnitPartB[0].string_id;
    document.getElementById("top-card").scrollIntoView();
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      this.answeredPopUpSecondQuestion &&
      !this.score.scorePogress.unit2PartB
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit2PartB");
      this.setLastUnit("/unit-3/part-a");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
