export const unitsNavigation = [
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-1",
    id: 2,
    isLink: 0,
    name: "Poglavlje 1",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z"
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-2",
    id: 3,
    isLink: 0,
    name: "Poglavlje 2",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z",
    dropdown_menu: [
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-2/part-a",
        id: 1,
        isLink: 0,
        name: "Lekcija A: Medijsko vlasništvo i selekcija vesti",
        tooltip: "/unit-2/part-a",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-2/part-b",
        id: 1,
        isLink: 0,
        name: "Lekcija B: Sloboda Izražavanja i Medijske Slobode",
        tooltip: "/unit-2/part-b",
        updated_at: "2021-10-14T00:01:51.000000Z"
      }
    ]
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-3",
    id: 4,
    isLink: 0,
    name: "Poglavlje 3",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z",
    dropdown_menu: [
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-3/part-a",
        id: 1,
        isLink: 0,
        name: "Lekcija A: Kako funkcionišu algoritmi",
        tooltip: "/unit-3/part-a",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-3/part-b",
        id: 1,
        isLink: 0,
        name: "Lekcija B: Kognitivne distorzije",
        tooltip: "/unit-3/part-b",
        updated_at: "2021-10-14T00:01:51.000000Z"
      }
    ]
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-4",
    id: 5,
    isLink: 0,
    name: "Poglavlje 4",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z",
    dropdown_menu: [
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-4/part-a",
        id: 1,
        isLink: 0,
        name: "Lekcija А: Šta su dezinformacije?",
        tooltip: "/unit-4/part-a",
        updated_at: "2021-10-14T00:01:51.000000Z"
      },
      {
        created_at: "2021-09-07T15:58:33.000000Z",
        href: "/unit-4/part-b",
        id: 2,
        isLink: 0,
        name: "Lekcija B: Kako da se zaštitimo od dezinformacija?",
        tooltip: "/unit-4/part-b",
        updated_at: "2021-10-14T00:01:51.000000Z"
      }
    ]
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-5",
    id: 6,
    isLink: 0,
    name: "Poglavlje 5",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z"
  },
  {
    created_at: "2021-09-07T15:58:33.000000Z",
    href: "/unit-6",
    id: 6,
    isLink: 0,
    name: "Poglavlje 6",
    tooltip: "/",
    updated_at: "2021-10-14T00:01:51.000000Z"
  }
];
