import Vue from "vue";
import VeeValidate from "vee-validate";
import certificateValidator from "./uniqueCertificate";
import emailValidator from "./uniqueEMail";

Vue.use(VeeValidate);
certificateValidator.init();
emailValidator.init();

VeeValidate.Validator.localize({
  en: {
    messages: {
      required: () => "*Polje je obavezno",
      email: () => "*E-pošta mora biti važeća",
      min: () => "*Ovo polje mora da sadrži najmanje 1 znak"
    }
  }
});
