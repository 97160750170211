<template>
  <div class="flexi-container page__internal page__unit-4-part-b">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[10].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderFourthUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-4/part-a')"
              class="sub-header__pagination--item"
              v-text="'Prethodno'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Nastavi'"
              @click="goToRoute('/unit-5')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 0"
      :sections="unitsSections[0].fourthUnitPartBTabA"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 1"
      :sections="unitsSections[0].fourthUnitPartBTabB"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 2"
      :sections="unitsSections[0].fourthUnitPartBTabC"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 3"
      :sections="unitsSections[0].fourthUnitPartBTabD"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 4"
      :sections="unitsSections[0].fourthUnitPartBTabE"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 5"
      :sections="unitsSections[0].fourthUnitPartBTabF"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 6"
      :sections="unitsSections[0].fourthUnitPartBTabG"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 7"
      :sections="unitsSections[0].fourthUnitPartBTabH"
      :activeSection="activeSection"
    />

    <!-- ChooseTab Section -->
    <div id="tab-section__scroll" />
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[10].sections[5]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          @click="chooseTabFun(value.id), selectActiveClass(key, value.title)"
          :class="{ active: itemKey === key }"
          class="tab-item"
        >
          <p v-text="value.title" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- Tab A Sections -->

    <!--First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 0"
      :inputData="inputData.internalPages[10].sections[1]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab B Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 1"
      :inputData="inputData.internalPages[10].sections[3]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--item"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab C Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 2"
      :inputData="inputData.internalPages[10].sections[11]"
      v-sectionview
      v-view="popUpFirstQuestion"
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <vue-plyr v-if="slotProps.inputData.contentLine">
            <div
              data-plyr-provider="youtube"
              :data-plyr-embed-id="slotProps.inputData.contentLine"
              class="flexi-wrapper__section--content--plyr"
            />
          </vue-plyr>
        </div>
        <p
          class="transcript-link"
          @click="expandTranscript"
          v-text="inputData.internalPages[10].sections[21].contentLine"
        />
        <transition
          name="slide"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <p
            v-show="expandVideoTranscript"
            class="transcript-text"
            id="transcript"
            v-html="inputData.internalPages[10].sections[21].description"
          />
        </transition>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 2"
      :inputData="inputData.internalPages[10].sections[12]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab D Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 3"
      :inputData="inputData.internalPages[10].sections[14]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab E Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 4"
      :inputData="inputData.internalPages[10].sections[15]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab F Sections -->

    <!--First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 5"
      :inputData="inputData.internalPages[10].sections[18]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab G Sections -->

    <!--First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 6"
      :inputData="inputData.internalPages[10].sections[19]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab H Sections -->

    <!--First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 7"
      :inputData="inputData.internalPages[10].sections[20]"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab Button -->
    <div id="tab-buttons">
      <div class="bottom-buttons">
        <button
          v-if="itemKey !== 0"
          v-text="textPrev()"
          @click="redirectPrev"
          class="flexi-btn"
        />
        <button
          v-if="itemKey !== 7"
          v-text="textNext()"
          @click="redirectNext"
          class="flexi-btn"
        />
      </div>
    </div>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[10].sections[0]"
    >
      <template v-slot:content="slotProps">
        <div class="bottom-buttons even">
          <div
            v-for="(value, key) in slotProps.inputData.widgets"
            :key="key"
            class="bottom-buttons"
          >
            <flexi-button
              v-if="key === 0"
              :text="value.buttonText"
              @button-clicked="goToRoute(value.buttonLink)"
              class="flexi-btn"
            />
            <flexi-button
              v-else-if="key === 1 && answeredPopUpFirstQuestion"
              :text="value.buttonText"
              @button-clicked="goToRoute(value.buttonLink)"
              class="flexi-btn"
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content" :class="additionalModalClass">
          <flexi-pop-up-question-multi
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[15]"
            @closeModal="closeModal"
            @removeModalClass="additionalModalClass = ''"
            @scoreIsCalculated="addModalClass"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderFourthUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestionMulti from "../../../components/FlexiPopUpQuestionMulti.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";

export default {
  name: "part-b",
  metaInfo() {
    return {
      title: "Poglavlje 4. Dezinformacije i manipulacije",
      meta: [
        {
          name: "description",
          content: "Poglavlje 4. Dezinformacije i manipulacije Lorem Ipsum."
        },
        {
          name: "og:title",
          content: "Poglavlje 4. Dezinformacije i manipulacije"
        },
        {
          name: "og:description",
          content: "Poglavlje 4. Dezinformacije i manipulacije Lorem Ipsum."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/2Rq_1646727151_unit4_seo.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Poglavlje 4. Dezinformacije i manipulacije"
        },
        {
          name: "twitter:description",
          content: "Poglavlje 4. Dezinformacije i manipulacije Lorem Ipsum."
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/2Rq_1646727151_unit4_seo.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestionMulti,
    FlexiScrollingSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      additionalModalClass: "",
      answeredPopUpFirstQuestion: false,
      chooseTab: "Headlines and manipulated photos",
      expandVideoTranscript: false,
      itemKey: 0,
      openContent: false,
      showPopUpFirstQuestion: false,
      subHeaderFourthUnit: subHeaderFourthUnit,
      subHeaderKey: 1
    };
  },
  methods: {
    addModalClass(boolean) {
      if (boolean) {
        this.additionalModalClass = "correct-modal-score";
      } else {
        this.additionalModalClass = "wrong-modal-score";
      }
    },
    chooseTabFun(txt) {
      this.chooseTab = txt;
    },
    closeModal(data) {
      if (this.showPopUpFirstQuestion === true) {
        this.answeredPopUpFirstQuestion = true;
        location.hash = "#tab-c-fake-expert-opinion";
      }

      if (!this.score.scorePogress.unit4PartB) {
        this.setSelectedAnswersPopUp(data);
      }

      this.$router.push("/unit-4/part-b");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit4PartB) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    textPrev() {
      if (this.itemKey === 1) {
        return "Clickbait";
      } else if (this.itemKey === 2) {
        return "Manipulisanje fotografijama";
      } else if (this.itemKey === 3) {
        return "Botovi i trolovi";
      } else if (this.itemKey === 4) {
        return "Mišljenje lažnih eksperata";
      } else if (this.itemKey === 5) {
        return "Prikriveno oglašavanje";
      } else if (this.itemKey === 6) {
        return "Manipulacije informacijama";
      } else if (this.itemKey === 7) {
        return "Manipulacije na društvenim mrežama";
      }
    },
    textNext() {
      if (this.itemKey === 0) {
        return "Manipulisanje fotografijama";
      } else if (this.itemKey === 1) {
        return "Botovi i trolovi";
      } else if (this.itemKey === 2) {
        return "Mišljenje lažnih eksperata";
      } else if (this.itemKey === 3) {
        return "Prikriveno oglašavanje";
      } else if (this.itemKey === 4) {
        return "Manipulacije informacijama";
      } else if (this.itemKey === 5) {
        return "Manipulacije na društvenim mrežama";
      } else if (this.itemKey === 6) {
        return "Alati za proveru činjenica";
      }
    },
    redirectPrev() {
      this.smoothScroll("tab-section__scroll");
      if (this.itemKey === 1) {
        this.itemKey = 0;
      } else if (this.itemKey === 2) {
        this.itemKey = 1;
      } else if (this.itemKey === 3) {
        this.itemKey = 2;
      } else if (this.itemKey === 4) {
        this.itemKey = 3;
      } else if (this.itemKey === 5) {
        this.itemKey = 4;
      } else if (this.itemKey === 6) {
        this.itemKey = 5;
      } else if (this.itemKey === 7) {
        this.itemKey = 6;
      }
    },
    redirectNext() {
      this.smoothScroll("tab-section__scroll");
      if (this.itemKey === 0) {
        this.itemKey = 1;
      } else if (this.itemKey === 1) {
        this.itemKey = 2;
      } else if (this.itemKey === 2) {
        this.itemKey = 3;
      } else if (this.itemKey === 3) {
        this.itemKey = 4;
      } else if (this.itemKey === 4) {
        this.itemKey = 5;
      } else if (this.itemKey === 5) {
        this.itemKey = 6;
      } else if (this.itemKey === 6) {
        this.itemKey = 7;
      }
    },
    selectActiveClass(key) {
      this.itemKey = key;
      if (key === 0) {
        setTimeout(() => {
          const elem = document.getElementById("tab-a-headlines");
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else if (key === 1) {
        setTimeout(() => {
          const elem = document.getElementById("tab-b-bots-and-trolls");
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else if (key === 2) {
        setTimeout(() => {
          const elem = document.getElementById("tab-c-fake-expert-opinion");
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else if (key === 3) {
        setTimeout(() => {
          const elem = document.getElementById(
            "tab-d-manipulation-with-the-original-source"
          );
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else if (key === 4) {
        setTimeout(() => {
          const elem = document.getElementById("tab-e-fact-checking-tools");
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else if (key === 5) {
        setTimeout(() => {
          const elem = document.getElementById(
            "tab-f-manipulacije-informacijama"
          );
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else if (key === 6) {
        setTimeout(() => {
          const elem = document.getElementById(
            "tab-g-manipulacije-na-drustvenim-mrezama"
          );
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else {
        setTimeout(() => {
          const elem = document.getElementById(
            "tab-h-alati-za-proveru-cinjenica"
          );
          elem.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].fourthUnitPartBTabA[0].string_id;
    document.getElementById("top-card").scrollIntoView();
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit4PartB
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit4PartB");
      this.setLastUnit("/unit-4/part-c");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
