<template>
  <div class="flexi-slider">
    <div class="flexi-slider__header">
      <slot
        name="header"
        v-bind="{ currentItem, inputData, changeSlide, currentSliderIndex }"
      >
        <div
          class="flexi-slider__header--item"
          v-for="(value, key) in inputData"
          :key="key"
          @click="changeSlide(key)"
        >
          <div
            class="flexi-slider__header--item__title"
            :class="{ 'active-title': currentSliderIndex === key }"
            v-text="value.title"
          />
          <div
            v-if="headerImage"
            class="flexi-slider__header--item__square"
            :class="{ 'active-square': currentSliderIndex === key }"
            :style="{ backgroundImage: 'url(' + value.img + ')' }"
          />
          <div
            v-else
            class="flexi-slider__header--item__square"
            :class="{ 'active-square': currentSliderIndex === key }"
          />
        </div>
      </slot>
    </div>
    <transition
      :name="transitionOn ? transitionName : ''"
      :mode="transitionMode"
    >
      <div
        class="flexi-slider__wrapper"
        v-for="i in [currentSliderIndex]"
        :key="i"
      >
        <slot name="item" v-bind="{ currentItem, computedIndex }">
          <div class="flexi-slider__wrapper--content">
            <span
              class="flexi-slider__wrapper--content__title"
              v-text="currentItem.title"
            />
            <span
              class="flexi-slider__wrapper--content__subTitle"
              v-text="currentItem.subTitle"
            />
            <div
              class="flexi-slider__wrapper--content__paragraph"
              v-html="currentItem.paragraph"
            />
          </div>
          <img class="flexi-slider__wrapper--image" :src="currentItem.img" />
        </slot>
      </div>
    </transition>
    <a class="next" @click="next">
      <div class="pagination">
        <span class="text" v-text="nextButtonText" />
        <div class="svg" />
      </div>
    </a>
    <a class="prev" @click="prev">
      <div class="pagination pagination__left">
        <span class="text" v-text="prevButtonText" />
        <div class="svg" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "flexi-slider",
  props: {
    inputData: {
      type: Array,
      default: () => {
        return [
          {
            title: "Some Title 1",
            paragraph: "Some Lorem Ipsum 1",
            img:
              "https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg"
          },
          {
            title: "Some Title 2",
            paragraph: "Some Lorem Ipsum 2",
            img:
              "https://cdn.pixabay.com/photo/2016/02/17/23/03/usa-1206240_1280.jpg"
          },
          {
            title: "Some Title 3",
            paragraph: "Some Lorem Ipsum 3",
            img:
              "https://cdn.pixabay.com/photo/2015/05/15/14/27/eiffel-tower-768501_1280.jpg"
          },
          {
            title: "Some Title 4",
            paragraph: "Some Lorem Ipsum 4",
            img:
              "https://cdn.pixabay.com/photo/2016/12/04/19/30/berlin-cathedral-1882397_1280.jpg"
          }
        ];
      }
    },
    currentIndex: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    turnTimer: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    timerInterval: {
      type: Number,
      default: () => {
        return 2000;
      }
    },
    nextButtonText: {
      type: String,
      default: () => {
        return "Next";
      }
    },
    transitionOn: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    transitionName: {
      type: String,
      default: () => {
        return "slider";
      }
    },
    transitionMode: {
      type: String,
      default: () => {
        return "out-in";
      }
    },
    prevButtonText: {
      type: String,
      default: () => {
        return "Prev";
      }
    },
    headerImage: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      timer: null,
      currentSliderIndex: this.currentIndex
    };
  },
  mounted: function() {
    if (this.turnTimer) {
      this.startSlide();
    }
  },
  methods: {
    startSlide() {
      this.timer = setInterval(this.next, this.timerInterval);
    },
    next() {
      console.log(this.currentSliderIndex);
      console.log(this.inputData.length);
      if (this.currentSliderIndex < this.inputData.length - 1) {
        this.currentSliderIndex += 1;
        this.$emit("clicked-next");
      }
    },
    prev() {
      if (this.currentSliderIndex > 0) {
        this.currentSliderIndex -= 1;
        this.$emit("clicked-prev");
      }
    },
    changeSlide(index) {
      this.currentSliderIndex = index;
    }
  },
  computed: {
    currentItem: function() {
      return this.inputData[
        Math.abs(this.currentSliderIndex) % this.inputData.length
      ];
    },
    computedIndex() {
      if (this.currentItem) {
        var result = "";
        this.inputData.filter((elem, index) => {
          if (elem.id === this.currentItem.id) {
            result = index;
          }
        });
        return result + 1;
      } else {
        return null;
      }
    }
  }
};
</script>
