import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Unit1 from "../views/Units/Unit1.vue";
import Unit2 from "../views/Units/Unit2.vue";
import Unit2PartA from "../views/Units/Unit2/PartA.vue";
import Unit2PartB from "../views/Units/Unit2/PartB.vue";
import Unit3 from "../views/Units/Unit3.vue";
import Unit3PartA from "../views/Units/Unit3/PartA.vue";
import Unit3PartB from "../views/Units/Unit3/PartB.vue";
import Unit4 from "../views/Units/Unit4.vue";
import Unit4PartA from "../views/Units/Unit4/PartA.vue";
import Unit4PartB from "../views/Units/Unit4/PartB.vue";
import Unit5 from "../views/Units/Unit5.vue";
import Unit6 from "../views/Units/Unit6.vue";
import FinalTest from "../views/FinalTest.vue";

import PasswordReset from "../views/PasswordReset.vue";
import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/unit-1",
    name: "unit-1",
    component: Unit1
  },
  {
    path: "/unit-2",
    name: "unit-2",
    component: Unit2
  },
  {
    path: "/unit-2/part-a",
    name: "unit-2-part-a",
    component: Unit2PartA
  },
  {
    path: "/unit-2/part-b",
    name: "unit-2-part-b",
    component: Unit2PartB
  },
  {
    path: "/unit-3",
    name: "unit-3",
    component: Unit3
  },
  {
    path: "/unit-3/part-a",
    name: "unit-3-part-a",
    component: Unit3PartA
  },
  {
    path: "/unit-3/part-b",
    name: "unit-3-part-b",
    component: Unit3PartB
  },
  {
    path: "/unit-4",
    name: "unit-4",
    component: Unit4
  },
  {
    path: "/unit-4/part-a",
    name: "part-a",
    component: Unit4PartA
  },
  {
    path: "/unit-4/part-b",
    name: "part-b",
    component: Unit4PartB
  },
  {
    path: "/unit-5",
    name: "unit-5",
    component: Unit5
  },
  {
    path: "/unit-6",
    name: "unit-6",
    component: Unit6
  },
  {
    path: "/final-test",
    name: "final-test",
    component: FinalTest
  },
  {
    path: "/password-reset/:token",
    component: PasswordReset
  },
  {
    path: "*",
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
