export const unitsSections = [
  {
    firstUnit: [
      {
        title: "Želimo ti dobrodošlicu na kurs!",
        string_id: "welcome-to-the-course",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "Moje medijsko okruženje",
        string_id: "consumption-log",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      },
      {
        title: "Prepoznaj i obuzdaj",
        string_id: "name-it-to-tame-it",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -50
      },
      {
        title: "Vrste medijskih sadržaja",
        string_id: "types-of-content",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "Činjenice vs. mišljenje - kviz",
        string_id: "facts-vs-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      }
    ],
    secondUnitPartA: [
      {
        title: "Vrste tradicionalni mediji",
        string_id: "types-of-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Medijski pluralizam",
        string_id: "private-non-private-media",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -550
      },
      {
        title: "Medijsko vlasništvo: Privatni mediji",
        string_id: "private-online-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Formiranje uređivačke politike",
        string_id: "state-media-in-Albania",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Kako priča dolazi do nas?",
        string_id: "non-private-non-for-profit",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -550
      },
      {
        title: "Organizacija medija i donošenje uređivačkih odluka",
        string_id: "ownership-and-broadcasting",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Medijska koncentracija",
        string_id: "medijska-koncentracija",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Mediji civilnog društva",
        string_id: "mediji-civilnog-drustva",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      }
    ],
    secondUnitPartB: [
      {
        title: "Ograničenja slobode izražavanja",
        string_id: "how-does-a-story",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "KVIZ – Zabranjeno ili dozvoljeno",
        string_id: "kviz–zabranjeno-ili-dozvoljeno",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Medijske slobode",
        string_id: "freedom-of-press",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Novinarski standardi",
        string_id: "journalistic-standards",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      }
    ],
    thirdUnitPartA: [
      {
        title: "Društvene mreže kao izvor informacija",
        string_id: "social-media-as-a-newssource",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Personalizovani algoritmi i informacioni mehur",
        string_id: "personalized-algorithms",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Kviz: Algoritmi i društvene mreže",
        string_id: "quiz-algorithms",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    thirdUnitPartB: [
      {
        title: "Kognitivne distorzije: šta su i zašto se dešavaju?",
        string_id: "cognitive-distortions",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Pristrasnost",
        string_id: "common-biases",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Kako možemo prevazići kognitivne distorzije?",
        string_id: "kako-mozemo-prevazici",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartA: [
      {
        title: "Privlačenje pažnje",
        string_id: "attention-hacking",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Uzroci dezinformisanja",
        string_id: "types-of-information-disorder",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Strategije manipulisanja u medijima",
        string_id: "strategije-manipulisanja-u-medijima",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "KVIZ - Jedan dan i redakciji",
        string_id: "kviz-jedan-dan-u-redakciji",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      }
    ],
    fourthUnitPartBTabA: [
      {
        title: "Clickbait",
        string_id: "tab-a-headlines",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -300
      }
    ],
    fourthUnitPartBTabB: [
      {
        title: "Manipulisanje fotografijama",
        string_id: "tab-b-bots-and-trolls",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      }
    ],
    fourthUnitPartBTabC: [
      {
        title: "Botovi i trolovi",
        string_id: "tab-c-fake-expert-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -300
      },
      {
        title: "Life Hacks: Kako otkriti botove i trolove",
        string_id: "tab-c-advertorial",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -300
      }
    ],
    fourthUnitPartBTabD: [
      {
        title: "Mišljenje lažnih eksperata",
        string_id: "tab-d-manipulation-with-the-original-source",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      }
    ],
    fourthUnitPartBTabE: [
      {
        title: "Prikriveno oglašavanje",
        string_id: "tab-e-fact-checking-tools",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabF: [
      {
        title: "Manipulacije informacijama",
        string_id: "tab-f-manipulacije-informacijama",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabG: [
      {
        title: "Manipulacije na društvenim mrežama",
        string_id: "tab-g-manipulacije-na-drustvenim-mrezama",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabH: [
      {
        title: "Alati za proveru činjenica (fact-checking)a",
        string_id: "tab-h-alati-za-proveru-cinjenica",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartC: [
      {
        title: "Hate speech vs Freedom of speech",
        string_id: "hate-speach",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "How to Report Hate Speech Online",
        string_id: "how-to-report-hate-speech",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Quiz: Inclusive Language",
        string_id: "quizz-inclusive-language",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -150
      }
    ],
    fourthUnitPartD: [
      {
        title: "What is Mean World Syndrome?",
        string_id: "what-is-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Case study",
        string_id: "the-fear-instinct",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Are We in Control of Our Decisions?",
        string_id: "what-should-we-do-to-overcome-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      }
    ],
    fifthUnit: [
      {
        title: "Uvod u stereotipe",
        string_id: "uvod-u-stereotipe",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Govor mržnje vs. Sloboda govora",
        string_id: "govor-mržnje-vs-sloboda-govora",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      },
      {
        title:
          "Kako govor mržnje može podstaći animozitet i kako mu se suprotstaviti",
        string_id: "kako-govor-mržnje",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Kako prijaviti govor mržnje online",
        string_id: "kako-prijaviti-govor-mržnje-online",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      }
    ],
    sixthUnit: [
      {
        title: "Promislimo i proverimo",
        string_id: "transcript-section",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Imaj na umu!",
        string_id: "imaj-na-umu",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      }
    ]
  }
];
