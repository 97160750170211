<template>
  <div class="flexi-pop-up-question__wrapper">
    <div class="flexi-quiz__content">
      <div>
        <div class="flexi-quiz__content--question">
          <span
            class="question"
            v-text="question.question"
            :data-aos="contentAos ? contentAosType : ''"
            :data-aos-duration="contentAosDuration"
          />
        </div>
        <div
          class="flexi-quiz__content--answer"
          v-for="(value, key) in question.answers"
          :key="key"
          :data-aos="contentAos ? contentAosType : ''"
          :data-aos-duration="contentAosDuration"
        >
          <div
            class="answer"
            :class="{
              'wrong-answer': !value.correct && value.id === selectedAnswerId,
              'correct-answer': value.correct && value.id === selectedAnswerId
            }"
            @click="selectAnswer(value, value.id, value.correct)"
          >
            <div class="circle" />
            <span class="text" v-text="value.answer" />
          </div>
        </div>
        <transition mode="in-out" name="fade">
          <div v-if="selectedAnswer" class="score-description">
            <span
              v-if="checkCorrectAnswer()"
              v-text="'Odličan, dobar posao.'"
              class="correct-score"
            />
            <span
              class="wrong-score"
              v-else
              v-text="'Ne odustajte, potražite pravi odgovor u ovom odeljku.'"
            />
          </div>
        </transition>
        <div class="button__wrapper" :class="{ active: active }">
          <button @click="closeModal" v-text="'Zatvori'" class="flexi-btn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentAos: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    contentAosType: {
      type: String,
      default: () => {
        return "fade-right";
      }
    },
    contentAosDuration: {
      type: String,
      default: () => {
        return "1500";
      }
    },
    question: {
      type: Object
    }
  },
  data() {
    return {
      active: false,
      selectedAnswer: false,
      selectedAnswerCorrect: null,
      selectedAnswerId: null,
      selectedAnswerObject: null
    };
  },
  methods: {
    checkCorrectAnswer() {
      if (this.selectedAnswerObject) {
        this.active = true;
        return this.selectedAnswerObject.correct;
      } else {
        return false;
      }
    },
    closeModal() {
      if (!this.user) {
        this.exitModal();
      } else {
        if (this.selectedAnswer) {
          this.$emit("closeModal", [this.selectedAnswerObject]);
          this.restartQuestion();
        }
      }
    },
    exitModal() {
      document.getElementById("pop-up-question").remove();
    },
    selectAnswer(answer, id, correct) {
      if (!this.selectedAnswer) {
        this.selectedAnswer = true;
        this.selectedAnswerObject = answer;
        this.selectedAnswerId = id;
        this.selectedAnswerCorrect = correct;
      }
    },
    restartQuestion() {
      this.active = false;
      this.selectedAnswer = false;
      this.selectedAnswerCorrect = null;
      this.selectedAnswerId = null;
      this.selectedAnswerObject = null;
    }
  }
};
</script>
