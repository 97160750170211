<template>
  <div class="flexi-container page__internal page__unit-1">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <span v-text="'Poglavlje 1'" class="sub-header__unit" />
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/')"
              class="sub-header__pagination--item"
              v-text="'Prethodno'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Nastavi'"
              @click="goToRoute('/unit-2')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].firstUnit"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[0]"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/KxO_1634752319_video%201.png"
          />
        </div>
        <p
          class="transcript-link"
          @click="expandTranscriptIndex(0)"
          v-text="inputData.internalPages[0].sections[9].contentLine"
        />
        <transition
          name="slide"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <p
            v-show="expandVideoTranscript"
            class="transcript-text"
            v-html="inputData.internalPages[0].sections[9].description"
          />
        </transition>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[6]"
      v-sectionview
    >
      <template v-slot:header="slotProps">
        <div class="flexi-wrapper__section--header--title">
          <h3 v-text="slotProps.inputData.title" />
        </div>
        <div v-if="!showCategory">
          <div class="flexi-wrapper__section--header--section">
            <img
              :src="slotProps.inputData.img"
              :alt="inputData.internalPages[0].sections[6].title"
              class="flexi-wrapper__section--header--section--img"
            />
            <p
              v-html="slotProps.inputData.description"
              class="flexi-wrapper__section--header--section--description"
            />
            <flexi-button
              @button-clicked="startCategory"
              :text="slotProps.inputData.buttonText"
              class="flexi-wrapper__section--header--section--button"
            />
          </div>
        </div>
        <div
          v-else-if="showCategory && !showConsumptionResult"
          class="flexi-wrapper__section--header--section category"
        >
          <span
            v-if="language === 'AL'"
            v-text="'Kategorije:'"
            class="flexi-wrapper__section--header--section--category--header"
          />
          <span
            v-else
            v-text="'Choose a category:'"
            class="flexi-wrapper__section--header--section--category--header"
          />
          <div
            v-for="(value, key) in slotProps.inputData.widgets"
            :key="key"
            @click="selectActiveKey(key)"
            class="flexi-wrapper__section--header--section--category--item"
            :class="{ disabled: ifConsumptionKeyIsSaved(key) }"
          >
            <img
              :src="value.img"
              :alt="inputData.internalPages[0].sections[6].title"
              @click="selectActiveKey(key)"
              class="flexi-wrapper__section--header--section--category--img"
            />
            <span
              v-text="value.title"
              @click="selectActiveKey(key)"
              class="flexi-wrapper__section--header--section--category--title"
            />
          </div>
          <div
            v-if="!consumptionInProgress"
            class="
              flexi-wrapper__section--header--section--category--pre-result
            "
          >
            <span class="pre-result" v-text="consumptionResultPreText" />
            <flexi-button
              @button-clicked="showConsumptionLogResult"
              :text="'Pokaži rezultate'"
            />
          </div>
        </div>
        <div
          v-else-if="showConsumptionResult"
          class="flexi-wrapper__section--header--section total-result"
        >
          <h2 v-text="'Results'" />
          <h3 v-text="consumptionResultFinalTitle" />
          <p v-text="consumptionResultFinalText" />
          <flexi-button
            @button-clicked="restartConsumptionLog"
            :text="'Ponovo'"
          />
        </div>
        <div
          v-if="
            itemKey === 0 ||
              itemKey === 1 ||
              itemKey === 2 ||
              itemKey === 3 ||
              itemKey === 4
          "
          class="flexi-wrapper__section--header--section choosed"
        >
          <div
            v-for="(value, key) in slotProps.inputData.widgets"
            :key="key"
            v-show="itemKey === key"
            class="flexi-wrapper__section--header--section--category--item"
          >
            <span v-text="value.title" class="choosed--title" />
            <p v-html="value.paragraph" class="choosed--paragraph" />

            <div v-if="value.footerHeader" class="choosed--item">
              <p v-text="value.footerHeader" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' sata'" />
              </div>
            </div>
            <div v-if="value.footerText" class="choosed--item">
              <p v-text="value.footerText" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value2"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value2"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' sata'" />
              </div>
            </div>
            <div v-if="value.email" class="choosed--item">
              <p v-text="value.email" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value3"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value3"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' sata'" />
              </div>
            </div>
            <div v-if="value.buttonText" class="choosed--item">
              <p v-text="value.buttonText" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value4"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value4"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' sata'" />
              </div>
            </div>

            <div v-if="itemKey === 2" class="choosed--item">
              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[itemKey].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[itemKey].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' sata'" />
              </div>
            </div>

            <div v-if="itemKey === 3" class="choosed--item">
              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[itemKey].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[itemKey].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' sata'" />
              </div>
            </div>

            <div v-if="itemKey === 4" class="choosed--item">
              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[itemKey].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[itemKey].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' sata'" />
              </div>
            </div>
            <flexi-button
              v-if="consumptionInProgress"
              @button-clicked="saveConsumptionLogStep"
              :text="'sačuvaj'"
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Third Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[4]"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--paragraph">
          <p v-html="slotProps.inputData.description" />
          <p
            v-html="slotProps.inputData.widgets[0].description"
            class="middle"
          />

          <div id="quizOne" class="quizz">
            <div v-if="!showQuizzOne" class="quizz__block">
              <div class="quizz__block--subTitle">
                <h3 v-text="slotProps.inputData.subTitle" />
              </div>
              <div class="quizz__block--button">
                <flexi-button
                  @button-clicked="startQuizz('quizOne', 0)"
                  :text="slotProps.inputData.buttonText"
                  v-view="popUpFirstQuestion"
                />
              </div>
            </div>

            <flexi-quiz
              v-else
              :isQuiz="true"
              :inputData="inputData.quizzes[7]"
              :changeSlideOnHeader="true"
              :resultOptions="{
                limit1: 3,
                limit2: 12
              }"
              :precent="precentQuizz"
              :language="language"
            >
              <template v-slot:header="slotProps">
                <div
                  v-if="!slotProps.showResult"
                  class="flexi-quiz__header--content"
                >
                  <span v-text="slotProps.computedIndex" />
                  <span v-text="'/'" />
                  <span v-text="slotProps.inputData.questions.length" />
                </div>
                <div v-else />
              </template>
              <template v-slot:question="slotProps">
                <div class="flexi-quiz__content--question">
                  <span class="question" v-text="slotProps.question" />
                  <div class="flexi-quiz__content--question--items">
                    <img class="image" :src="slotProps.img" />
                    <wheel-of-emotions />
                  </div>
                </div>
              </template>
            </flexi-quiz>
          </div>
          <span v-text="slotProps.inputData.widgets[0].buttonText + ' '" />
          <a
            class="transcript-link"
            @click="expandTranscriptIndex(1)"
            v-text="slotProps.inputData.widgets[0].buttonLink"
          />
          <transition
            name="slide"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <p
              v-show="expandVideoTranscriptSecond"
              class="transcript-text"
              v-html="slotProps.inputData.widgets[0].paragraph"
            />
          </transition>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Fourth Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[2]"
      v-sectionview
      v-view="popUpSecondQuestion"
    >
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpSecondQuestion"
          class="hidden"
          v-view="popUpSecondQuestion"
        />
        <div id="video2" class="video__wrapper" @click="togglePoster('video2')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/B5p_1634752319_video%202.png"
          />
        </div>
        <p
          class="transcript-link"
          @click="expandTranscriptIndex(2)"
          v-text="inputData.internalPages[0].sections[10].contentLine"
        />
        <transition
          name="slide"
          @before-enter="beforeEnter"
          @enter="enter"
          @before-leave="beforeLeave"
          @leave="leave"
        >
          <p
            v-show="expandVideoTranscriptThird"
            class="transcript-text"
            v-html="inputData.internalPages[0].sections[10].description"
          />
        </transition>
      </template>
    </flexi-wrapper>

    <!-- Fifth Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[3]"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div v-if="!showQuizz" class="flexi-wrapper__section--content__block">
          <div class="flexi-wrapper__section--content__block--subTitle">
            <h3 v-text="slotProps.inputData.subTitle" />
          </div>
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content__block--img"
          />
          <div
            v-text="slotProps.inputData.contentTitle"
            class="flexi-wrapper__section--content__block--contentTitle"
          />
          <div
            v-html="slotProps.inputData.description"
            class="flexi-wrapper__section--content__block--description"
          />
          <div class="flexi-wrapper__section--content__block--button">
            <flexi-button
              @button-clicked="
                startQuizz(inputData.internalPages[0].sections[3].string_id, 1)
              "
              :text="slotProps.inputData.buttonText"
            />
          </div>
        </div>
        <flexi-quiz
          v-else
          :inputData="inputData.quizzes[0]"
          :changeSlideOnHeader="true"
          :resultOptions="{
            limit1: 3,
            limit2: 12
          }"
          :precent="precentQuizz"
          :language="language"
        >
          <template v-slot:header="slotProps">
            <div
              v-if="!slotProps.showResult"
              class="flexi-quiz__header--content"
            >
              <span v-text="slotProps.computedIndex" />
              <span v-text="'/'" />
              <span v-text="slotProps.inputData.questions.length" />
            </div>
            <div v-else />
          </template>
        </flexi-quiz>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.internalPages[0].sections[7]"
    >
      <template v-slot:content="slotProps">
        <flexi-button
          v-text="slotProps.inputData.buttonText"
          @button-clicked="goToRoute(slotProps.inputData.buttonLink)"
        />
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content" :class="additionalModalClass">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[0]"
            @closeModal="closeModal"
          />
          <flexi-pop-up-question
            v-if="showPopUpSecondQuestion"
            :question="inputData.quizzes[3].questions[3]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlexiQuiz from "../../components/FlexiQuiz.vue";
import FlexiPopUpQuestion from "../../components/FlexiPopUpQuestion.vue";
import FlexiScrollingSlider from "../../components/FlexiScrollingSlider.vue";
import WheelOfEmotions from "../../components/WheelOfEmotions.vue";

export default {
  name: "unit-1",
  metaInfo() {
    return {
      title: "Poglavlje 1. Naše medijsko okruženje",
      meta: [
        {
          name: "description",
          content:
            "Šta podrazumevamo pod „savremenim medijima“ i kako oni funkcionišu? Identifikujte svoje navike potrošnje medija i vrste informacija sa kojima se bavite. Naučite kako da razlikujete činjenice od mišljenja i prepoznate manipulativni sadržaj. Savremeni mediji se odnose na različite oblike lako dostupnog digitalnog medijskog sadržaja, uključujući platforme društvenih medija, podkastove, blogove, digitalne tekstove, video, zvuk, slike, animacije i razne druge oblike multimedija. Razlikuje se od „tradicionalnih medija“, poput televizije, radija i novina, po tome što omogućava interaktivni proces i skoro trenutnu komunikaciju, kao i pristup izvorima iz celog sveta."
        },
        {
          name: "og:title",
          content: "Poglavlje 1. Naše medijsko okruženje"
        },
        {
          name: "og:description",
          content:
            "Šta podrazumevamo pod „savremenim medijima“ i kako oni funkcionišu? Identifikujte svoje navike potrošnje medija i vrste informacija sa kojima se bavite. Naučite kako da razlikujete činjenice od mišljenja i prepoznate manipulativni sadržaj. Savremeni mediji se odnose na različite oblike lako dostupnog digitalnog medijskog sadržaja, uključujući platforme društvenih medija, podkastove, blogove, digitalne tekstove, video, zvuk, slike, animacije i razne druge oblike multimedija. Razlikuje se od „tradicionalnih medija“, poput televizije, radija i novina, po tome što omogućava interaktivni proces i skoro trenutnu komunikaciju, kao i pristup izvorima iz celog sveta."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/PbX_1646727151_unit1_seo.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Poglavlje 1. Naše medijsko okruženje"
        },
        {
          name: "twitter:description",
          content:
            "Šta podrazumevamo pod „savremenim medijima“ i kako oni funkcionišu? Identifikujte svoje navike potrošnje medija i vrste informacija sa kojima se bavite. Naučite kako da razlikujete činjenice od mišljenja i prepoznate manipulativni sadržaj. Savremeni mediji se odnose na različite oblike lako dostupnog digitalnog medijskog sadržaja, uključujući platforme društvenih medija, podkastove, blogove, digitalne tekstove, video, zvuk, slike, animacije i razne druge oblike multimedija. Razlikuje se od „tradicionalnih medija“, poput televizije, radija i novina, po tome što omogućava interaktivni proces i skoro trenutnu komunikaciju, kao i pristup izvorima iz celog sveta."
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/PbX_1646727151_unit1_seo.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiQuiz,
    FlexiScrollingSlider,
    WheelOfEmotions
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      additionalModalClass: "",
      answeredPopUpFirstQuestion: false,
      answeredPopUpSecondQuestion: false,
      consumptionInProgress: true,
      consumptionLog: [
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        }
      ],
      consumptionResultPreText:
        "Hajde da sumiramo vašu dnevnu interakciju sa medijima!",
      consumptionResultFinalTitle: null,
      consumptionResultFinalText: null,
      consumptionTotalHours: null,
      expandVideoTranscript: false,
      expandVideoTranscriptSecond: false,
      expandVideoTranscriptThird: false,
      itemKey: false,
      openContent: false,
      precentQuizz: [
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "79%",
          y: "21%"
        },
        {
          x: "85%",
          y: "15%"
        },
        {
          x: "71%",
          y: "29%"
        },
        {
          x: "65%",
          y: "35%"
        },
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "75%",
          y: "25%"
        },
        {
          x: "72%",
          y: "28%"
        },
        {
          x: "87%",
          y: "13%"
        },
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "75%",
          y: "25%"
        },
        {
          x: "72%",
          y: "28%"
        },
        {
          x: "87%",
          y: "13%"
        }
      ],
      savedConsumptionKeys: [],
      showQuizzOne: false,
      showQuizz: false,
      showCategory: false,
      showConsumptionResult: false,
      showPopUpFirstQuestion: false,
      showPopUpSecondQuestion: false,
      sixthSectionWidgets: {
        options: {
          button: false,
          date: false,
          description: true,
          img: true,
          paragraphOn: false,
          subTitle: false,
          titleClickable: false
        }
      }
    };
  },
  methods: {
    addModalClass(boolean) {
      if (boolean) {
        this.additionalModalClass = "correct-modal-score";
      } else {
        this.additionalModalClass = "wrong-modal-score";
      }
    },
    calculateHours() {
      var result = [];

      for (let i = 0; i < this.consumptionLog.length; i++) {
        result.push(parseFloat(this.consumptionLog[i].value1));
        result.push(parseFloat(this.consumptionLog[i].value2));
        result.push(parseFloat(this.consumptionLog[i].value3));
        result.push(parseFloat(this.consumptionLog[i].value4));
      }

      this.consumptionTotalHours = result.reduce(function(acc, val) {
        return acc + val;
      }, 0);

      if (this.consumptionTotalHours < 2) {
        this.consumptionResultFinalTitle = "Do 2 sata";
        this.consumptionResultFinalText =
          "Čini se da ne provodite previše vremena u interakciji sa medijima. Niste pretrpani informacijama i možete efikasno da upravljate svojim vremenom. Ali nije važna samo količina informacija; pronalaženje kvalitetnih informacija i korišćenje vaših veština kritičkog mišljenja su od suštinskog značaja. Imajte ovo na umu i saznajte više u sledećim jedinicama.";
      } else if (
        this.consumptionTotalHours >= 2 &&
        this.consumptionTotalHours <= 8
      ) {
        this.consumptionResultFinalTitle = "Od 2 do 5 sata";
        this.consumptionResultFinalText =
          "Mediji zauzimaju značajan deo vašeg dana. Dobijate dosta informacija. Ne zaboravite da upravljate količinom vremena koje provodite u interakciji sa medijima. Nije samo količina informacija važna; pronalaženje kvalitetnih informacija i korišćenje vaših veština kritičkog mišljenja su od suštinskog značaja. Imajte ovo na umu i saznajte više u sledećim jedinicama.";
      } else {
        this.consumptionResultFinalTitle = "8 sata ili više";
        this.consumptionResultFinalText =
          "Vau! Mnogo vremena provodite u interakciji sa medijima. Da li ste sigurni da ste u stanju da obradite sve te informacije i da istovremeno ostanete fokusirani i kritični? Previše buke informacija može vas preplaviti i smanjiti vašu sposobnost kritičkog razmišljanja. Ne zaboravite da upravljate količinom vremena koje provodite u interakciji sa medijima. Ali nije samo količina informacija važna; pronalaženje kvalitetnih informacija i korišćenje vaših veština kritičkog mišljenja su od suštinskog značaja. Imajte ovo na umu i saznajte više u sledećim jedinicama.";
      }
    },
    closeModal(data) {
      if (this.showPopUpFirstQuestion === true) {
        this.answeredPopUpFirstQuestion = true;
        location.hash = "#name-it-to-tame-it";
        this.$router.push("/unit-1");
      } else {
        this.answeredPopUpSecondQuestion = true;
        location.hash = "#types-of-content";
        this.$router.push("/unit-1");
      }
      if (!this.score.scorePogress.unit1) {
        this.setSelectedAnswersPopUp(data);
      }
    },
    ifConsumptionKeyIsSaved(key) {
      return this.savedConsumptionKeys.includes(key);
    },
    saveConsumptionLogStep() {
      if (this.savedConsumptionKeys.length === 0) {
        this.savedConsumptionKeys.push(this.itemKey);
      } else if (!this.savedConsumptionKeys.includes(this.itemKey)) {
        this.savedConsumptionKeys.push(this.itemKey);

        if (this.savedConsumptionKeys.length === 5) {
          this.calculateHours();
          this.consumptionInProgress = false;
        }
      }
      this.smoothScroll("consumption-log");
      this.itemKey = false;
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit1) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpSecondQuestion = false;
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    popUpSecondQuestion(e) {
      if (!this.score.scorePogress.unit1) {
        if (!this.answeredPopUpSecondQuestion) {
          if (e.percentTop > 0.608 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = false;
            this.showPopUpSecondQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    restartConsumptionLog() {
      this.consumptionInProgress = true;
      this.consumptionLog = [
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        }
      ];

      this.consumptionTotalHours = null;
      this.consumptionResultFinalTitle = null;
      this.consumptionResultFinalText = null;
      this.itemKey = false;
      this.savedConsumptionKeys = [];
      this.showConsumptionResult = false;
    },
    selectActiveKey(key) {
      if (this.savedConsumptionKeys.length === 0) {
        this.itemKey = key;
      } else if (!this.savedConsumptionKeys.includes(key)) {
        this.itemKey = key;
      }
    },
    startCategory() {
      this.smoothScroll("consumption-log");
      this.showCategory = true;
    },
    startQuizz(id, index) {
      this.smoothScroll(id);

      if (index === 0) {
        this.showQuizzOne = true;
      } else {
        this.showQuizz = true;
      }
    },
    showConsumptionLogResult() {
      this.showConsumptionResult = true;
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].firstUnit[0].string_id;
    document.getElementById("top-card").scrollIntoView();
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      this.answeredPopUpSecondQuestion &&
      !this.score.scorePogress.unit1
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setLastUnit("/unit-2/part-a");
      this.setUnitScore("unit1");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
